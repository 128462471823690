<template>
  <div>
    <h1>Javascript tips</h1>
    <h2>Tableaux</h2>
    <h3>Liens utiles</h3>
    <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array" target="blank">MDN web docs</a>
    <h3>Tips</h3>
    <ul>
      <li>Pour retrouver les éléments "distincts" d'un tableau, sur un valeur "simple", on peut utiliser la fonction <b>filter()</b> de la façon suivante :
        <code><i>monTableau</i>.filter((value, index, self) => { return self.indexOf(value) === index; })</code>
      </li>
    </ul>
    <hr/>
    <b-radio v-for="comp in compulsories" :key="comp" :value="comp" v-model="compViewed" >{{ comp }}</b-radio>
    <div>
      <b-check v-model="compIncludeModel" inline>Diagramme</b-check>
      <b-check v-model="compIncludeText" inline>Texte</b-check>
    </div>
    <compulsory-image v-model="compViewed" :includeModel="compIncludeModel" :includeText="compIncludeText"></compulsory-image>
  </div>
</template>

<script>
import CompulsoryImage from '../../components/CompulsoryImage.vue';
export default{
  components: {CompulsoryImage },
  data(){
    return {
      compViewed : "", compIncludeModel: true, compIncludeText: false,
      compulsories: ['DI-02', 'DI-03', 'DI-09', 'DI-14', 'DI-16', 'DI-19', 'foo'],
    };
  },
  mounted(){
    this.$hideLoader();
  }
}
</script>

<template>
    <b-container fluid>
        <b-img v-if="includeModel" :src="url" thumbnail fluid/>
        <b-img v-if="includeText" :src="txtUrl" thumbnail/>
    </b-container>
</template>

<script>

export default {
    props:{ value: { type: String, default: ''},
            includeModel: { type: Boolean, default: true},
            includeText: { type:Boolean, default: false},
            teamOption: { type: String, default: ''},
        },

    data(){
        return {
            url: '',
            txtUrl: '',
        }
    },
    methods:{
        async refresh(){
            var opt = (this.teamOption != '') ? "-" + this.teamOption : "";
            var ret = (await import('@/assets/Compulsories/' + this.value + opt + '.jpg')).default;
            var txtRet = (await import('@/assets/Compulsories/' + this.value + '.text.jpg')).default;
            this.url = ret;
            this.txtUrl = txtRet;
        }
    },
    async mounted(){
        await this.refresh();
    },
    watch:{
        value : async function(){
            await this.refresh();
        },
        teamOption: async function(){
            await this.refresh();
        }
    }
}
</script>
